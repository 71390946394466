import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page non trouvée" />
    <div className="uk-text-center uk-section">
      <h1>Page 404</h1>
      <p>Page non trouvée</p>
    </div>
  </Layout>
)

export default NotFoundPage
